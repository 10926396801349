import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    Measuring: {
        overflowY:"hidden",
        height: '100%',
    },
    containerPre: {
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center', 
        flexDirection: 'column', 
        width: '100%', 
        marginTop: '30px',
        ['@media (max-width: 600px)']: {
            width: '349px',
            marginTop: '50px',
        },
    },
    button:{
        background: 'black',
        borderRadius: '30px',
        width: '291px',
        padding: '15px 0',
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '140.1%',
        textAlign: 'center',
        color: 'white',
        cursor: 'pointer',
        marginTop: '40px',
        ['@media (max-width: 600px)']: {
            width: '349px',
            marginTop: '30px',
        },
    },
    camera: {
        width: '389px', 
        height:'345px',
        borderRadius: '11px',
        objectFit: 'cover',
        ['@media (max-width: 600px)']: {
            width: '342px', 
            height:'301px',
        },
    },
    cameraMargin: {
        ['@media (max-width: 600px)']: {
            marginTop: '35px',
        },
    },
    onlyMobile: {
        display: 'none',
        ['@media (max-width: 600px)']: {
            display: 'block',
        },
    },
    mobileInvisible: {
        marginTop: '40px',
        ['@media (max-width: 600px)']: {
            visibility: 'hidden',
            marginTop: '40pxZZZZ',
        },
    },
    onlyDesktop: {
        ['@media (max-width: 600px)']: {
            display: 'none',
        },
    },
    bigTextPre: {
        fontFamily: 'Space Grotesk', 
        fontStyle: 'normal', 
        fontWeight: '600', 
        fontSize: '24px', 
        lineHeight: '129%', 
        textAlign: 'center', 
        width: '570px', 
        ['@media (max-width: 600px)']: {
            width: '349px',
            fontWeight: '600', 
            fontSize: '20px', 
        }
    },
    bigText: {
        fontFamily: 'Space Grotesk', 
        fontStyle: 'normal', 
        fontWeight: '600', 
        fontSize: '24px', 
        lineHeight: '129%', 
        textAlign: 'center', 
        width: '40%', 
        ['@media (max-width: 600px)']: {
            width: '349px',
            fontWeight: '600', 
            fontSize: '20px', 
        }
    },
    smallText: {
        fontFamily: 'Space Grotesk', 
        fontStyle: 'normal', 
        fontWeight: 'normal', 
        fontSize: '24px', 
        lineHeight: '129%', 
        textAlign: 'center', 
        width: '40%', 
        ['@media (max-width: 600px)']: {
            width: '349px',
            fontSize: '14px', 
        }
    },
    cameraButton: {
        position: 'absolute', 
        top: '100%', 
        left: '50%', 
        zIndex: '2',
        transform: 'translateX(-50%) translateY(-50%)',
        ['@media (max-width: 600px)']: {
            transform: 'translateX(-50%) translateY(0%)',
            marginTop: '23px',
            zIndex: '2'
        }
    },
}));