import {
    IMAGES_UPLOAD_SET,
    IMAGES_UPLOAD_RESET,
} from '../constants/imagesConstants'


export const imagesUploadReducer = (state = { images:{} }, action) => {
    switch (action.type) {
   
        case IMAGES_UPLOAD_SET:
            return {
                ...state,
                images: action.payload
            }

        // case IMAGES_UPLOAD_RESET:
        //     return {}


        default:
            return state
    }
}