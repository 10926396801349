import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({

    AppBar: {
        backgroundColor: 'black',
        ['@media (max-width: 600px)']: {
            height: "120px"
          },
  

     
    },

    ToolBar :{
        
        display: "flex",
        justifyContent: "space-between",

        ['@media (max-width: 600px)']: {
            height: "120px",
          flexDirection: "column",
          justifyContent: "space-around",
        },

    },

    SocialIcons:{
     
        width: "150px",
        display: "flex",
        justifyContent:"space-between",
    },

    Link:{
        '&:hover': {
            backgroundColor: 'black',
            color: "white",
          },

    }

}));