import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    buttonContainer: {
       width:"60%", display: 'flex', margin:"auto", textAlign:"center",
       ["@media (max-width:981px)"] : {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
    }

    },
    container: {
        width: '100%',
        display: 'flex',
    
        ["@media (max-width:600px)"] : {
            alignItems: 'center',
            flexDirection: 'column',
        }
    },
    title: {
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '116.6%',
        color: '#171717',
        ["@media (max-width:600px)"] : {
            fontFamily: 'Space Grotesk',
            lineHeight: '128.6%',
            textAlign: 'center',
        }
    },
    textBlock: {
        marginTop: '91px',
        ["@media (max-width:600px)"] : {
            marginTop: '69px',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
        }
    },
    result: {
        display: 'flex', 
        flexDirection: 'column', 
        width:'250px', 
        marginRight: '25px',
        ["@media (max-width:600px)"] : {
            margin: '0 0',
        }
    },
    result2: {
        display: 'flex', 
        flexDirection: 'column', 
        width:'250px', 
        marginRight: '25px',
        ["@media (max-width:600px)"] : {
            margin: '0 0',
            marginTop: '30px'
        }
    },
    results: {
        display: 'flex', 
        flexWrap: 'wrap', 
        marginTop: '17px',
        ["@media (max-width:600px)"] : {
            marginTop: '100px',
        }
    },
    subtitle: {
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '116.6%',
        color: '#4C4C4B',
        width: '85%', 
        marginTop: '20px',
        ["@media (max-width:600px)"] : {
            textAlign: 'center',
            fontSize: '18px',
            lineHeight: '151.6%',
        }
    },
    mobileCenter: {
        ["@media (max-width:600px)"] : {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        }
    },
    buttonCenterMobile: {
        marginRight: 'auto', 
        marginLeft: '20%',
        ["@media (max-width:600px)"] : {
            marginLeft:'auto',
        }
    },
    desktop: {
        width: '548px',
        height: '519px',
        marginTop: '61px',
        marginLeft: '84px',
        objectFit: 'contain', 
        marginRight: '50px',
        ["@media (max-width:600px)"] : {
            display: 'none',
        }
    },
    desktopOnly: {
        ["@media (max-width:600px)"] : {
            display: 'none',
        }
    },
    
    imageSubtitle: {
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '10px',
        lineHeight: '150.1%',
        color: '#292929',
        ["@media (max-width:600px)"] : {
            textAlign: 'center',
            fontSize: '18px',
        }
    },
    imageTitle: {
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '150.5%',
        ["@media (max-width:600px)"] : {
            textAlign: 'center',
            fontFamily: 'Space Grotesk',
            lineHeight: '128.6%',
            textAlign: 'center',
            fontSize: '20px',
        }
    },
    mobileOnly: {
        display: 'none',
        ["@media (max-width:600px)"] : {
            display: 'block',

        }
    },
    button: {
        background: 'black',
        borderRadius: '30px',
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '140.1%',
        textAlign: 'center',
        color: 'white',
        cursor: 'pointer',
        marginTop: '55px',
        width: '149px',
        marginLeft: '130px',
        padding: '13px 0',
        width: '291px',
        ["@media (max-width:600px)"] : {
            marginTop: '100px',
            padding: '11px 0',
            fontSize: '16px',
            fontWeight: '600',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
}));