
function feedback0(landmarks) {
  var r_ear = landmarks[454];
  var l_ear = landmarks[234];
  var l_outer_bottom = landmarks[127];
  var l_outer_top = landmarks[162];
  var l_inner = landmarks[33];
  var top = landmarks[10];
  var bot = landmarks[152]
  if (Math.abs(1 - (r_ear.z/l_ear.z)) > 0.2) {
    if (1 - (r_ear.z / l_ear.z) < 0) {
      return [false, 'Turn head left.'];
    } else {
      return [false, 'Turn head right'];
    }
  } else if (Math.abs(1 - (l_outer_bottom.y / l_inner.y)) > 0.15) {
    return [false, 'Tilt head downward.'];
  } else if (Math.abs(1 - (l_inner.y / l_outer_top.y)) > 0.15) {
    return [false, 'Tilt head upwards.'];
  } else if (Math.abs(1 - (top.x/bot.x)) > 0.15) {
    if (1 - (top.x/bot.x) < 0) {
      return [false, 'Tilt head away from left shoulder.'];
    } else {
      return [false, 'Tilt head away from right shoulder.'];
    }

  }else {
    return [true, 'Ready to capture.'];
    // set ready
  }
}

function feedback1(landmarks, left) {
  var r_ear = landmarks[454];
  var l_ear = landmarks[234];
  var main = r_ear;
  var secondary = l_ear;
  var direction = 'right.';
  if (left) {
    main = l_ear;
    secondary = r_ear;
    direction = 'left.';
  }
  if (Math.abs(main.z/secondary.z) < 2.5 || (main.z < secondary.z)) {

      return [false, 'Turn head ' + direction];
  }  else {
    return [true, 'Ready to capture.'];
    // set ready
  }
}


export const orientationFeedback = function(landmarks, state) {
  landmarks = landmarks[0];
  if (state == 3 || state == 9) {
    var result = feedback0(landmarks);
  } else if (state == 5) {
    var result = feedback1(landmarks, false);
  } else if (state == 7) {
    var result = feedback1(landmarks, true);
  }
  if (state == 3) {
    result.push("images/measure/MeasureStep2CenterOverlay.png");
  } else if (state == 5) {
    result.push("images/measure/MeasureStep2LeftOverlay.png");
  } else if (state == 7) {
    result.push("images/measure/MeasureStep2RightOverlay.png");
  } else if (state == 9) {
    result.push("images/measure/MeasureStep2SmileOverlay.png");
  }
  return result;
}
