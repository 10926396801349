import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import ParfaitWellcappedLogo from '../../assets/ParfaitWellcappedLogo.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Link from '@material-ui/core/Link';
import { useStyles } from "./BarWellcapped.styled";

const BarWellcapped = () => {


    const classes = useStyles();

  return (
    <div  className={classes.AppBar} position="static">
      <Container maxWidth="xl" >
        <Toolbar className={classes.ToolBar} disableGutters >
        <img src={ParfaitWellcappedLogo} style={{"max-width":"100%",  "width": "auto", "height": "100px",  "margin-left": "auto", "margin-right": "auto", "margin-top":"25px", "margin-bottom": "25px"}}></img>
        </Toolbar>
      </Container>
    </div>
  );
};
export default BarWellcapped;
