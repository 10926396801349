import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    
    "@-moz-keyframes spin": {
      "100%": {
        '-moz-transform': 'rotate(360deg)',
      }
    },
    "@-webkit-keyframes spin": {
      "100%": {
        '-webkit-transform': 'rotate(360deg)',
      }
    },
    "@keyframes spin": {
      "100%": {
        '-webkit-transform': 'rotate(360deg)',
        transform: 'rotate(360deg)',
      }
    },
    spin: {
        '-webkit-animation': `$spin 2s linear infinite`,
        '-moz-animation': `$spin 2s linear infinite`,
        animation: `$spin 2s linear infinite`,
    },
    title: {
        fontFamily: 'Space Grotesk',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '36px',
        lineHeight: '128.6%',
    },
}));