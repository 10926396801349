import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    input: {
        backgroundColor: 'transparent',
        color: 'black',
        border: 'none',
        borderBottom: '1px solid black',
        width: '100%',
        padding: '12px 20px',
    },
    containerImg:{
        display:"flex",
        flexDirection:"row",
        width: "100%",
        ['@media (max-width: 1300px)']:{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    container: { 
        marginLeft: "40%",
        width: "50%",
        textAlign: 'center',
        marginTop: '119px',
        ['@media (max-width: 1300px)']:{
            marginTop: '50px',
            marginLeft: '0',
            marginRight: '0',
            width: '80%',
        }
    },
    title: {
        fontFamily: 'Space Grotesk',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '36px',
        lineHeight: '128.6%',
        color:"#000000",
        textAlign: 'center',
        width: '90%',
        margin: '0 auto',
        ['@media (max-width: 600px)']:{
            fontSize: '30px',
            width: '100%',
        }
    },
    img: {
        position:'absolute',
        bottom: '0', 
        left: '0', 
        width: '780px',
        height:"730px",

        // border:"2px red solid",
        objectFit: 'cover',
        ['@media (max-width: 1300px)']:{
            position: 'relative',
            height: 'auto',
            width: '100vw',
        }
    },
    description: {
        marginTop:"20px",
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '200%',
        textAlign: 'center',
        ["@media (max-width:600px)"] : {
            textAlign: 'center',
            fontSize: '18px',
            lineHeight: '151.6%',
        }
    },
    button: {
        background: 'black',
        borderRadius: '30px',
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '140.1%',
        textAlign: 'center',
        color: 'white',
        cursor: 'pointer',
        marginTop: '55px',
        width: '149px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '13px 0',
        ["@media (max-width:600px)"] : {
            marginTop: '55px',
            marginBottom: '20px',
            width: '217px',
            padding: '11px 0',
            fontSize: '14.6px'
        }
    },
}));
