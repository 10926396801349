import {
    STYLE_ADD,
    STYLE_REMOVE,
} from '../constants/styleConstants'



export const styleReducer = (state = { }, action) => {
    switch (action.type) {
        case STYLE_ADD:
            return {
                style: action.payload
            }
        case STYLE_REMOVE:
               return {}
        default:
            return state
    }
}