import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {imagesUploadReducer} from './redux/reducers/imagesReducers'
import { styleReducer } from './redux/reducers/styleReducers'
import {
    userRegisterReducer,
    userLoginReducer,
    userDetailsReducer,
    userUpdateProfileReducer
  } from './redux/reducers/userReducers';
import {shippingAddressReducer, totalPriceReducer,orderCreateReducer,} from './redux/reducers/orderReducers'

const reducer= combineReducers({
    userRegister: userRegisterReducer,
    userLogin: userLoginReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    imagesUpload: imagesUploadReducer,
    style: styleReducer,
    shippingAddress: shippingAddressReducer,
    totalPrice: totalPriceReducer,
    orderCreate: orderCreateReducer,
})

const imagesFromStorage = localStorage.getItem('imagesHairtelligence') ?
    JSON.parse(localStorage.getItem('imagesHairtelligence')) : {}

const styleFromStorage = localStorage.getItem('styleHairtelligence') ?
    JSON.parse(localStorage.getItem("styleHairtelligence")) : {}

const shippingAddressFromStorage = localStorage.getItem('shippingAddressHairtelligence') ?
    JSON.parse(localStorage.getItem("shippingAddressHairtelligence")) : {}

const userInfoFromStorage = localStorage.getItem('userInfoHairtelligence')
    ? JSON.parse(localStorage.getItem('userInfoHairtelligence'))
    : null;



const initialState={ 
 
 shippingAddress:shippingAddressFromStorage,
 imagesUpload: imagesFromStorage,
 style:styleFromStorage,
 totalPriceInfo:{},
 userLogin: { userInfo: userInfoFromStorage },
}


const middleware=[thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)))


export default store