import * as React from 'react'
import * as types from './actions'
import {productsSample} from './helper/sample-data'
const ShoppingContext = React.createContext({})

function ShoppingProvider({children}) {
  const initialState = {
    products: productsSample,
    productSelected: {},
  }

  function shoppingReducer(state, action) {
    switch (action.type) {
      case types.PRODUCT_FOUND:
        return {
          ...state,
          productSelected: action.payload,
        }
      default: {
        throw new Error(`unhandled action type: ${action.type}`)
      }
    }
  }
  const [state, dispatch] = React.useReducer(shoppingReducer, initialState)
  const {products, productSelected} = state

  const [userSelection, setUserSelection] = React.useState({
    wigTypes: '5X5-HDCLW',
    textures: 'BW',
    length: '14',
  })
  function getUserSelection(key, value) {
    setUserSelection({
      ...userSelection,
      [key]: value,
    })
    console.log(key, value)
  }

  React.useEffect(() => {
    const {wigTypes, textures, length} = userSelection
    const queryString = `${wigTypes}-${textures}-${length}`
    const product = products.filter(
      product => product.productOffering === queryString,
    )[0]
    dispatch({type: types.PRODUCT_FOUND, payload: product})
  }, [userSelection])

  const value = React.useMemo(
    () => ({products, getUserSelection, productSelected}),
    [products, getUserSelection, productSelected],
  )

  return (
    <ShoppingContext.Provider value={value}>
      {children}
    </ShoppingContext.Provider>
  )
}

function useShoppingContext() {
  const context = React.useContext(ShoppingContext)
  if (context === undefined) {
    throw new Error('useShoppingContext must be used within a ShoppingProvider')
  }
  return context
}

export {ShoppingProvider, useShoppingContext}
