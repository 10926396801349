import React from "react";
import { useStyles } from "./How.styled";
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
const image = '/images/measuring.png';

export default function How({step, setStep, subStep, setSubStep}){
    const classes = useStyles();
    const {width} = useWindowDimensions()
    const video = '/images/video.png';
    const isMobile = width <= 600
    return (
        <div className={classes.container}>
            <div className={classes.block}>
                <div className={classes.title} >
                    How it works
                </div>
                <div className={classes.textDesktop}>
                    <div className={classes.description} >
                        We're here to help you every step of the way!
                    </div>
                    <div className={classes.description} >
                        You will use your credit card as a ruler so we can measure your cap size. 
                        Please make sure that your hairline is showing and your credit card information is not!
                    </div>
                    <div className={classes.description} >
                        Our software will find the best lace tint to match your skin tone. 
                        The better your lighting, the more we can get the most accurate color match for you.
                    </div>
                </div>

                <div className={`${classes.description} ${classes.textMobile}`}>
                    At Parfait, we use technology to ensure the correct lace tint 
                    and custom cap size for you by simply capturing a few selfies. 
                    With a credit card as your ruler, we will guide you through the 
                    process of virtually measuring your head. 
                    <br/>
                    All you need is a webcam and a card!
                </div>

                <video className={classes.imageMobile} playsinline  playsinline webkit-playsinline autoPlay loop muted>
              <source src="https://elasticbeanstalk-us-east-1-450454924520.s3.amazonaws.com/Ani+CC+fitting.mp4" type="video/mp4"/>
              </video>

                <div className={classes.titleBullet}>
                    We will take four photos that Ani is showing on the right.
                </div>

                <div className={classes.bulletPoints}>
                    <div style={{display: 'flex', flexDirection: 'column', marginRight: '40px'}}>
                        <div className={classes.descriptionBullet} style={{fontSize: '14px'}}>
                            1. Face forward and credit card centered
                        </div>
                        <div className={classes.descriptionBullet} style={{fontSize: '14px', marginTop: '10px'}}>
                            2. Face turned left and credit card on the right
                        </div>
                    </div>
                    <div className={classes.bulletWithSpace}>
                        <div className={classes.descriptionBullet} style={{fontSize: '14px'}}>
                            3. Face turned right and credit card on the left 
                        </div>
                        <div className={classes.descriptionBullet} style={{fontSize: '14px', marginTop: '10px'}}>
                            4. Smile, smize, be confident
                        </div>
                    </div>
                </div>

                <div className={classes.button}
                    onClick={() => {
                        setStep(step +1 );
                        setSubStep(subStep + 1);
                    }
                }>
                    Next
                </div>
            </div>
            
         
            <video className={classes.imageDesktop}  playsinline autoPlay loop muted>
              <source src="https://elasticbeanstalk-us-east-1-450454924520.s3.amazonaws.com/Ani+CC+fitting.mp4" type="video/mp4"/>
              </video>
        </div>
    )
}