export const productsSample = [
  {
    productOffering: '5X5-HDCLW-BW-14',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'BW',
    length: 14,
    lacePiec: '5X5-HDCL',
  },

  {
    productOffering: '5X5-HDCLW-BW-18',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'BW',
    length: 18,
    lacePiec: '5X5-HDCL',
  },
  {
    productOffering: '5X5-HDCLW-BW-24',
    qtyInStock: 5,
    retailPrice: '$499.99',
    pattern: 'BW',
    length: 24,
    lacePiec: '5X5-HDCL',
  },
  {
    productOffering: '5X5-HDCLW-DW-14',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'DW',
    length: 14,
    lacePiec: '5X5-HDCL',
  },
  {
    productOffering: '5X5-HDCLW-DW-18',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'DW',
    length: 18,
    lacePiec: '5X5-HDCL',
  },
  {
    productOffering: '5X5-HDCLW-DW-24',
    qtyInStock: 5,
    retailPrice: '$499.99',
    pattern: 'DW',
    length: 24,
    lacePiec: '5X5-HDCL',
  },
  {
    productOffering: '5X5-HDCLW-KSTR-14',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'KSTR',
    length: 14,
    lacePiec: '5X5-HDCL',
  },
  {
    productOffering: '5X5-HDCLW-KSTR-18',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'KSTR',
    length: 18,
    lacePiec: '5X5-HDCL',
  },
  {
    productOffering: '5X5-HDCLW-KSTR-24',
    qtyInStock: 3,
    retailPrice: '$499.99',
    pattern: 'KSTR',
    length: 24,
    lacePiec: '5X5-HDCL',
  },
  {
    productOffering: '13X6-HDCLW-BW-14',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'BW',
    length: 14,
    lacePiece: '13X6-HDFR',
  },
  {
    productOffering: '13X6-HDCLW-BW-18',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'BW',
    length: 18,
    lacePiece: '13X6-HDFR',
  },
  {
    productOffering: '13X6-HDCLW-BW-24',
    qtyInStock: 5,
    retailPrice: '$499.99',
    pattern: 'BW',
    length: 24,
    lacePiece: '13X6-HDFR',
  },
  {
    productOffering: '13X6-HDCLW-DW-14',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'DW',
    length: 14,
    lacePiece: '13X6-HDFR',
  },
  {
    productOffering: '13X6-HDCLW-DW-18',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'DW',
    length: 18,
    lacePiece: '13X6-HDFR',
  },
  {
    productOffering: '13X6-HDCLW-DW-24',
    qtyInStock: 4,
    retailPrice: '$499.99',
    pattern: 'DW',
    length: 24,
    lacePiece: '13X6-HDFR',
  },
  {
    productOffering: '13X6-HDCLW-KSTR-14',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'KSTR',
    length: 14,
    lacePiece: '13X6-HDFR',
  },
  {
    productOffering: '13X6-HDCLW-KSTR-18',
    qtyInStock: 0,
    retailPrice: '$499.99',
    pattern: 'KSTR',
    length: 18,
    lacePiece: '13X6-HDFR',
  },
  {
    productOffering: '13X6-HDCLW-KSTR-24',
    qtyInStock: 2,
    retailPrice: '$499.99',
    pattern: 'KSTR',
    length: 24,
    lacePiece: '13X6-HDFR',
  },
]
