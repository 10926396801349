import React from "react";
import { useStyles } from "./preLaunch.styled";
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

export default function PreLaunch({step, setStep, subStep, setSubStep}) {
    const classes = useStyles();
    const {width} = useWindowDimensions()
    // const isMobile = width <= 600

    return (
        <div className={classes.container} style={{textAlign:"center"}}>
            
            <div style={{width: '100%'}} className={`${classes.mobileCenter} ${classes.textBlock}`}>
                <div className={classes.title}>
                    All set! Your measurements and lace tint have been captured.
                </div>
                <div className={`${classes.mobileCenter}`}>
                <div className={`${classes.button}`} style={{marginLeft: "auto", marginRight: "auto", marginTop: "25px"}}
                    onClick={() => {
                        window.location.href='https://www.parfaithair.com'
                    }
                }>
                   Thank You for Your Submission!
                </div>
                </div>

            </div>
        </div>
    )
}
