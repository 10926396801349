import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        ["@media (max-width:600px)"] : {
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    block: {
        marginTop: '70px',
        marginLeft:"175px",
        display: 'flex', 
        flexDirection: 'column', 
        width: '65%', 
        ["@media (max-width:600px)"] : {
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft:"0",
            width: '90%', 
            marginTop: '50px',
        }
    },
    title: {
        color:"#000000",
        fontFamily: 'Space Grotesk',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '48px',
        lineHeight: '120.5%',
        textAlign:"left"
    },
    textDesktop: {
        ["@media (max-width:600px)"] : {
            display: 'none',
        }
    },
    textMobile: {
        display: 'none',
        ["@media (max-width:600px)"] : {
            display: 'block',
        }
    },
    description: {
        marginTop:"25px",
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '140.1%',
        textAlign: 'left',
        color: '#3A3A3A',
        width: '85%', 
        ["@media (max-width:600px)"] : {
            textAlign: 'center',
        }
    },
    button: {
        background: 'black',
        borderRadius: '30px',
        width: 'fit-content',
        marginLeft:"220px",
        padding: '15px 60px',
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '140.1%',
        textAlign: 'center',
        color: 'white',
        cursor: 'pointer',
        marginTop: '60px',
        ["@media (max-width:600px)"] : {
            marginLeft: '0',
            padding: '15px 0',
            width: '217px',
            marginTop: '45px',
            marginBottom: '38px',
        }
    },
    imageMobile: {
        display: 'none',
        ["@media (max-width:600px)"] : {
            marginTop: '20px',
            height: '300px',
            maxWidth: '300px',
            width: '100%',
            objectFit: 'cover', 
            borderRadius: '30.3209px',
            backgroundColor: '#EEF2FE',
            display: 'flex',
        }
    },
    imageDesktop: {
        marginTop: '49px',
        height: '435px', 
        width : '435px', 
        marginRight:"116px", 
        objectFit: 'cover', 
        borderRadius: '30.3209px',
        ["@media (max-width:600px)"] : {
            display: 'none',
        }
    },
    titleBullet: {
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '140.1%',
        color: '#3A3A3A',
        marginTop: '50px',
    },
    bulletPoints: {
        display: 'flex', 
        flexDirection: 'row', 
        marginTop: '20px',
        ["@media (max-width:600px)"] : {
            flexDirection: 'column',
        }
    },
    descriptionBullet: {
        color:"#3a3a3a",
        fontFamily: 'Roobert',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '140.1%',
        textAlign: 'left',
        ["@media (max-width:600px)"] : {
            textAlign: 'left',
        }
    },
    bulletWithSpace: {
        display: 'flex', 
        flexDirection: 'column',
        ["@media (max-width:600px)"] : {
            marginTop: '10px',
        }
    },
}));