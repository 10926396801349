import {
    
    SAVE_SHIPPING_ADDRESS,
    SAVE_TOTAL_PRICE,
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_CREATE_RESET,
    RESET_SHIPPING_ADDRESS,
    RESET_TOTAL_PRICE
        

} from '../constants/orderConstants'

export const shippingAddressReducer = (state = { }, action) => {
    switch (action.type) {
        case   SAVE_SHIPPING_ADDRESS:
            return {
                shippingAddress: action.payload
            }
        default:
            return state
    }
}

export const totalPriceReducer = (state = { }, action) => {
    switch (action.type) {
        case   SAVE_TOTAL_PRICE:
            return {
                totalPrice: action.payload
            }
        default:
            return state
    }
}


export const orderCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_CREATE_REQUEST:
            return {
                loading: true
            }

        case ORDER_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                order: action.payload
            }

        case ORDER_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case ORDER_CREATE_RESET:
            return {}


        case RESET_SHIPPING_ADDRESS:
                return {}
        
        case RESET_TOTAL_PRICE:
                    return {}


        default:
            return state
    }
}