import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  step: {
    display: 'block',
    width: '100%',
    // padding: '15px 0',
    height: '41px',
    position: 'relative',
  },
  reached: {
    background: '#FFDABF',
  },
  notReached: {
    background: '#EEF2FE',
  },
  text: {
    textAlign: 'center',
    fontFamily: 'Roobert',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '14px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  filler: {
    height: '100%',
    backgroundColor: '#FFDABF',
    width: props => `calc(100%*${props.subStep}/${props.subStepAmount})`,
    position: 'absolute',
    top: '0',
  },
}))
