import * as React from 'react'
const AuthContext = React.createContext({})

function AuthProvider({children}) {
  const initialState = {
    isAuth: true, // 🔦 false = user not logged, true = user logged.
  }

  function authReducer(state, action) {
    switch (action.type) {
      default: {
        throw new Error(`unhandled action type: ${action.type}`)
      }
    }
  }
  const [state, dispatch] = React.useReducer(authReducer, initialState)
  const {isAuth} = state

  const value = React.useMemo(() => ({isAuth}), [isAuth])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

function useAuthContext() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }
  return context
}

export {AuthProvider, useAuthContext}
