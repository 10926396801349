import React, {useState}from "react";
import { useStyles } from "./GetReadyWellcapped.styled";
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

export default function GetReady({step, setStep, subStep, setSubStep, emailInput, setEmailInput, orderIdInput, setOrderIdInput}){
    const image = '/images/measuring.png';
    const classes = useStyles();
    const {width} = useWindowDimensions()
    const [message, setMessage]=useState("")
    const [uncheckedMessage, setUncheckedMessage]=useState("")
    const [checked, setChecked]= useState(false)
    const isMobile = width <= 600
    return (
        <div className={classes.containerImg} >
            <div className={classes.container}>
                <div className={classes.title}>
                    Take my measurements!
                </div>
                <div className={classes.description}>
                    We use technology to ensure the correct lace tint and custom cap size for you by simply taking a few selfies.
                    <br /> 
                    You’ll take 4 selfies using a card as your ruler to capture your measurements.
                    <br />
                    <b>Use this app so we can customize your cap size and lace tint. <br/> <font color='red'> Make sure you’re in good lighting and have a card handy!</font></b>
                </div>
                <div >
                    
                     <label className={`${classes.description} ${classes.label}`}><span style={{color:"red"}}>{message}</span>Please enter your email:</label> <br/>
                     <input className={`${classes.description} ${classes.input}`}
                        type="text"
                        name="email"
                        value={emailInput}
                        onChange={e => { e.preventDefault(); setEmailInput(e.target.value) }}
                        required
                    />
                     <label className={`${classes.description} ${classes.label}`}>Order ID (optional):</label> <br/>
                     <input className={`${classes.description} ${classes.input}`}
                        type="text"
                        name="orderId"
                        value={orderIdInput}
                        onChange={e => { e.preventDefault(); setOrderIdInput(e.target.value) }}
                        required
                    />
                    <div className={'agree'}>
                        <br /><p><font color="red">{uncheckedMessage}</font></p>
                    <input
                            type="checkbox"
                            onClick={() =>{
                            setChecked(!checked)
                            }
                        }
                            name="checked"
                            id="agree"
                        />
                        <label htmlFor="agree">
                        &nbsp; By using our application, you agree 
                        </label>
                        <br/>
                        to our <a href="https://www.hairtelligence.com/privacy-policy">privacy policy</a> & <a href="https://www.hairtelligence.com/terms-of-use">terms and conditions</a>.
                        
        </div>
                </div>
            <div className={classes.button} onClick={() => {
                if (emailInput===undefined){
                    setMessage('Attention: ')
                }
                else if(checked===false){
                    setUncheckedMessage("To use this application, you must agree to our privacy policy and terms and conditions.")
                }
                else{
                    setStep(step + 1);
                    setSubStep(subStep + 1);
                }
                }}>
                    {isMobile ? "Customize your wig" : "Let’s go!"}
            </div>

            </div>
            
            <img src={image} className={classes.img} />
        </div>
    )
}
