import React, {useEffect, useRef, useState} from "react";
import { useStyles } from "./MeasuringLacetint.styled";
import Stepper from "../../components/stepper/Stepper";
import Webcam from "react-webcam";
import GetReady from "./components/getReady/GetReady";
import HowLaceTint from './components/how/HowLaceTint';
import LoadingLaceTint from './components/loading/LoadingLaceTint';
import Results from "./components/results/Results";
import { FaceMesh } from "@mediapipe/face_mesh";
import * as utils from "@mediapipe/camera_utils";
import * as draw from "@mediapipe/drawing_utils";
import * as fm from "@mediapipe/face_mesh";
import * as faceutils from "./util/face_utils";
import { useParams } from 'react-router-dom';
import Bar from './Bar'
import PreLaunch from "./components/preLaunch/preLaunch";
export default function MeasuringLacetint() {
    var currentImage = null;
    var camera = null;
    const classes = useStyles();
    const camButton = '/icons/CamButton.png';
    const retake = '/icons/Retake.png';
    const heart = '/images/measure/HeartShadder.png';
    const prePhotoImage1 = 'https://measure-overlay.s3.amazonaws.com/Measure_Step_1_full.png';
    const prePhotoImage2 = 'https://measure-overlay.s3.amazonaws.com/Measure_Step_2_full.png';
    const prePhotoImage3 = 'https://measure-overlay.s3.amazonaws.com/Measure_Step_3_full.png';
    const prePhotoImage4 = 'https://measure-overlay.s3.amazonaws.com/Measure_Step_4_full.png';
    const [step, setStep] = useState(1);
    const [taken, setTaken] = useState(false);
    const [running, setRunning] = useState(false);
    const [subStep, setSubStep] = useState(1);
    const camRef = useRef();
    const canvasRef = useRef(null);
    const [img1, setImg1] = useState();
    const [img2, setImg2] = useState();
    const [img3, setImg3] = useState();
    const [img4, setImg4] = useState();
    const drawLandmarks = draw.drawLandmarks;
    const drawConnectors = draw.drawConnectors;
    const params = useParams()
    const email = params.email
    const orderRowId= params.orderRowId
    const [cap_size, setCapSize] = useState();
    const [lace_tint, setLaceTint] = useState();
    const [lace_tint_description, setLaceTintDescription] = useState();
    const isNotSafariBrowser = !(navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1)
    const [overlaySrc, setOverlaySrc] = useState('https://measure-overlay.s3.amazonaws.com/Measure_Step_1.png');


    /* MEDIAPIPE */
    function onResults(results) {
       const videoWidth = camRef.current.video.videoWidth;
       const videoHeight = camRef.current.video.videoHeight;
       canvasRef.current.width = videoWidth;
       canvasRef.current.height = videoHeight;
       const canvasElement = canvasRef.current;
       const canvasCtx = canvasElement.getContext("2d");
       canvasCtx.save();
       currentImage = results.image.toDataURL();
       canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
       canvasCtx.drawImage(
         results.image,
         0,
         0,
         canvasElement.width,
         canvasElement.height
       );
			 if (results.multiFaceLandmarks) {
						for (const landmarks of results.multiFaceLandmarks) {
              var feedback = faceutils.orientationFeedback(results.multiFaceLandmarks, step);
              var feedback_status = feedback[0];
              var feedback_text = feedback[1];
              var feedback_pic = feedback[2];
              var feedback_elem = document.getElementById('feedback-text');
              feedback_elem.innerHTML = feedback_text;
              if (feedback_status) {
                document.getElementById('canvas_overlay').style.opacity = 0
                feedback_elem.style.color = "green";
              } else {
                feedback_elem.style.color = "red";
                document.getElementById('canvas_overlay').style.opacity = 100
                var tmpimg = new Image();
                tmpimg.src = feedback_pic;
                var width = canvasElement.width;
                var scale = canvasElement.width / tmpimg.width;
                var height = tmpimg.height * scale;
                var xstart = (canvasElement.width - width) / 2;
                var ystart = (canvasElement.height - height) / 2;
                canvasCtx.drawImage(
                   tmpimg,
                   xstart,
                   ystart,
                   width,
                   height
                );
              }
              // if feedback false, add madison overlay.
              if (isNotSafariBrowser) {
                  drawLandmarks(canvasCtx, landmarks, {color: '#C0C0C0FF', lineWidth: .5, radius: 2});
                  drawConnectors(
                      canvasCtx, landmarks, fm.FACEMESH_RIGHT_EYE,
                      {color: '#E0E0E070', lineWidth: 1});
                  drawConnectors(
                      canvasCtx, landmarks, fm.FACEMESH_RIGHT_EYEBROW,
                      {color: '#E0E0E070', lineWidth: 1});
                  drawConnectors(
                      canvasCtx, landmarks, fm.FACEMESH_LEFT_EYE,
                      {color: '#E0E0E070', lineWidth: 1});
                  drawConnectors(
                      canvasCtx, landmarks, fm.FACEMESH_LEFT_EYEBROW,
                      {color: '#E0E0E070', lineWidth: 1});
                  drawConnectors(
                      canvasCtx, landmarks, fm.FACEMESH_FACE_OVAL,
                      {color: '#E0E0E070', lineWidth: 1});
                  drawConnectors(
                      canvasCtx, landmarks, fm.FACEMESH_LIPS,
                      {color: '#E0E0E070', lineWidth: 1});
              }
						}
				}
			canvasCtx.restore();
		}
    /* END MEDIAPIPE */
    useEffect(() => {
        setTimeout(() => {
            setTaken(false);
        }, 1000)
    }, [taken])

    useEffect(() => {
        setOverlaySrc('https://measure-overlay.s3.amazonaws.com/Measure_Step_4.png');
    }, [step])

    const captureImage = (setter) => {
        //setter(camRef.current.getScreenshot())
        setter(currentImage);
    }

    const prePhotoComponent = (text, preImage, subText) => 
        <div className={classes.containerPre}>
            <div className={`${classes.bigTextPre} ${classes.onlyMobile}`}>
                {text}
            </div>
            <div className={`${classes.smallText} ${classes.onlyMobile}`}>
                {subText}
            </div>
            
            <img src={preImage} className={`${classes.camera} ${classes.cameraMargin}`}/>
            <div className={`${classes.bigTextPre} ${classes.onlyDesktop}`} style={{marginTop: '40px'}}>
                {text}
            </div>
            <div className={`${classes.smallText} ${classes.onlyDesktop}`}>
                {subText}
            </div>
            
            <div className={classes.button}
                onClick={() => {
                    setRunning(true);
                    setStep(step + 1);
                }
            }>
                I’m ready to take my photo
            </div>
        </div>

const takePhotoComponent = (image, saveImage, preTitle, preSubtitle, postTitle, postSubtitle, buttonText) =>
<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', marginTop: '50px'}}>

    {image ? 
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        
        <div className={`${classes.bigText} ${classes.onlyMobile}`}>
            {postTitle}
        </div>

        <div className={`${classes.smallText} ${classes.onlyMobile}`} style={{marginTop: '10px', fontWeight: 'normal'}}>
            {postSubtitle}
        </div>
        <div style={{position: "relative", marginTop: '45px'}} className={classes.camera}>
            <img src={image} className={classes.camera}/>
            <div style={{position: "absolute",width: '100%', height: '100%', top: '0', left: '0', background: taken? '#C7A98A': 'transparent', opacity: taken ? '0.5' : '0'}}/>
            <img src={heart} className={classes.camera} style={{position: "absolute",width: '103px', height: '90px',transform: 'translateX(-50%) translateY(-50%)', top: '50%', left: '50%', opacity: taken ? '0.5' : '0'}}/>


            <div style={{position: "absolute", top: '85%', left: '5%', display: 'flex', alignItems: 'center', cursor: 'pointer', display: taken ? 'none' : ''}}     onClick={() => {
        saveImage(null);
        setRunning(true);
      }
    }>
                <img src={retake} style={{marginRight: '10px'}}/>
                Retake
            </div>
            
            <div style={{}}/> 
        </div>
        
        <div className={`${classes.bigText} ${classes.onlyDesktop}`} style={{width: '100%', marginTop: '40px'}}>
            {postTitle}
        </div>

        <div className={`${classes.smallText} ${classes.onlyDesktop}`} style={{width: '100%', marginTop: '10px', fontWeight: 'normal'}}>
            {postSubtitle}
        </div>
        
        <div className={classes.button}
            onClick={() => {
                setStep(step + 1);
                setSubStep(subStep + 1);
            }
        }>
            {buttonText}
        </div>
    </div>
    : 
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <div className={`${classes.bigText} ${classes.onlyMobile}`}>
            {preTitle}
        </div>

        <div id="feedback-text" className={classes.smallText} style={{color: 'blue'}}>
            Loading Models... 
        </div>
        <div className={`${classes.smallText} ${classes.onlyMobile}`} style={{marginBottom: '40px'}}>
            {preSubtitle}
        </div>
        <div style={{position: "relative"}} className={classes.camera}>
            <canvas id="output_canvas" className={classes.camera} ref={canvasRef} />
            <img id="canvas_overlay" style={{height: "345px", marginTop: "-389px", marginLeft: "22px"}} src={overlaySrc} />
            <Webcam
                audio={false}
                ref={camRef} 
                screenshotFormat={"image/jpeg"}
                className={classes.camera}
                style={{ width: "0%", height: "0%" }}
            />
            <img src={camButton} className={classes.cameraButton}
                onClick={() => {
                    captureImage((value) => saveImage(value));
                    setTaken(true)
                    setRunning(false)
                    var feedback_elem = document.getElementById('feedback-text');
                    feedback_elem.innerHTML = "";
                    //setStep(step + 1);
                }
            }/>
        </div>
        
        <div className={`${classes.bigText} ${classes.mobileInvisible}`}>
            {preTitle}
        </div>

        <div className={`${classes.smallText} ${classes.mobileInvisible}`}>
            {preSubtitle}
        </div>
    </div>
    }
    
</div>

        const steps = [
         <GetReady step={step} subStep={subStep} setStep={setStep} setSubStep={setSubStep} email={email} />,
        <HowLaceTint step={step} subStep={subStep} setStep={setStep} setSubStep={setSubStep}/>, 
        // prePhotoComponent('Place your credit card along your hairline in the middle of your forehead.', prePhotoImage1), 
        // takePhotoComponent(img1, (value) => setImg1(value), 'Press the capture button when you\'ve placed your credit card along your hairline in the middle of your forehead.', 'Please make sure your entire hairline is visible', 'First photo taken!', 'Are you ready to take the second one?', 'I sure am!'),
        // prePhotoComponent('Place your credit card along your hairline on your left temple.', prePhotoImage2), 
        // takePhotoComponent(img2, (value) => setImg2(value), 'Press the capture button once you have your credit card in the right placement.', '', 'Second photo taken!', 'Ready to move on?', 'I sure am!'),
        // prePhotoComponent('Place your credit card along your hairline on your right temple.', prePhotoImage3), 
        // takePhotoComponent(img3, (value) => setImg3(value), 'Press the capture button once you have your credit card in the right placement.', '', 'You\'ve got this! Third photo done, one more to go!', 'I am ready for my fourth photo to be taken!', 'Here i come!'),
        prePhotoComponent('Smile, smize or be fierce!', prePhotoImage4, 'This is the photo we\'ll be using to match your lace tint'), 
        takePhotoComponent(img4, (value) => setImg4(value), 'Press the capture button when you\'re ready! Smile, smize, or be fierce!', 'This is the photo we\'ll (eventually!) be using to  match your lace tint!', 'You\'re done!', 'Time to match your lace tint...', 'I want to see my results!'),
        <LoadingLaceTint step={step} subStep={subStep} setStep={setStep} setSubStep={setSubStep} email={email} orderRowId = {orderRowId} img4={img4} setLaceTint={setLaceTint} setLaceTintDescription={setLaceTintDescription} />, 
        <PreLaunch step={step} subStep={subStep} setStep={setStep} setSubStep={setSubStep}/>, 
        // <Results step={step} subStep={subStep} setStep={setStep} setSubStep={setSubStep} lace_tint={lace_tint} lace_tint_description={lace_tint_description} cap_size={cap_size}/>, 
      
    ];
    /* BEGIN MEDIAPIPE */
		useEffect(() => {
      setTimeout(() => {
      const faceMesh = new FaceMesh({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
        },
      });
      faceMesh.setOptions({
        maxNumFaces: 1,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
        selfieMode: true,
      });
      faceMesh.onResults(onResults);
      if (
        typeof camRef.current !== "undefined" &&
        camRef.current !== null
      ) {
        camera = new utils.Camera(camRef.current.video, {
          onFrame: async () => {
            if (camRef.current) {
              await faceMesh.send({ image: camRef.current.video });
            }
          },
          width: 480,
          height: 640,
        });
        camera.start();
       }
      }, 1000)
    }, [running]);
    /* END MEDIAPIPE */

    return (
        <div className={classes.Measuring} >
           <Bar></Bar>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {steps[step]}
            </div>
        </div>
    )
}
