import React, {useState} from 'react'
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Measuring from './containers/measuring/Measuring'
import MeasuringImages from './containers/measuring/MeasuringImages'
import MeasuringWellcapped from './containers/measuring/MeasuringWellcapped'
import MeasuringLacetint from './containers/measuring/MeasuringLacetint'

export default function App() {

  return (
    <Router>
      
      <Switch>
        <Route exact path="/:email/:orderRowId">
          <Measuring />
        </Route>
        <Route exact path="/lacetint/:email/:orderRowId">
          <MeasuringLacetint />
        </Route>
        <Route exact path="/wellcapped">
          <MeasuringWellcapped />
        </Route>
      </Switch>
      <Route exact path="/">
          <MeasuringImages />
      </Route>
    </Router>
  )
}
