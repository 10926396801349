import './App.css'
import {ThemeProvider} from '@material-ui/core/styles'
import theme from './theme'
import Routes from './Routes'
import {AuthProvider} from './context/auth-user.context'
import {ShoppingProvider} from './context/shopping.context'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ShoppingProvider>
          <Routes />
        </ShoppingProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
